// Controlled component for calendar.
import React, { useEffect, useState } from "react";

import { useAppDispatch } from "../../../store/hooks.ts";
import {
	setSnackbarOpen,
	setSnackbarSeverity,
	setSnackbarText,
} from "../../../store/slices/snackbarSlice.ts";

import {
	calculateFeeEstimate,
	calculateSinglePtFeeEstimate,
} from "../helper/feeCalculation.ts";

import {
	DialogTitle,
	Button,
	DialogContent,
	Box,
	DialogActions,
	CircularProgress,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	Dialog,
	TextField,
	IconButton,
	Tooltip,
	// Tooltip,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CalendarDialog = ({
	open,
	selectedEvent,
	schedules,
	saving,
	deleting,
	handleDelete,
	handleClose,
	handleUpdateEventItems,
	handleAddEventItem,
	handleDeleteEventItem,
	handleSaveEventItems,
}) => {
	const dispatch = useAppDispatch();

	// Track input changes.
	const [changesMade, setChangesMade] = useState({});
	const [newItemValues, setNewItemValues] = useState({});

	// Reset tracking when dialog is closed
	useEffect(() => {
		if (!open) {
			setChangesMade({});
			setNewItemValues({});
		}
	}, [open]);

	const handleItemChange = (eventIndex, itemIndex, newValue) => {
		const updatedEvents = [...selectedEvent.events];

		const currentItems = JSON.parse(
			updatedEvents[eventIndex].actual_items || "[]"
		);

		currentItems[itemIndex] = newValue;

		updatedEvents[eventIndex].actual_items = JSON.stringify(currentItems);

		handleUpdateEventItems(selectedEvent.id, updatedEvents);
		setChangesMade((prev) => ({ ...prev, [eventIndex]: true }));
	};

	// const [newItemValue, setNewItemValue] = useState<string>("");

	const handleNewItemInputChange = (eventIndex, value) => {
		setNewItemValues((prev) => ({ ...prev, [eventIndex]: value }));
	};

	const handleItemAdd = (eventIndex) => {
		handleAddEventItem(eventIndex, newItemValues[eventIndex]);
		setNewItemValues((prev) => ({ ...prev, [eventIndex]: "" }));
		setChangesMade((prev) => ({ ...prev, [eventIndex]: true }));
	};

	const handleItemDelete = (eventIndex, itemIndex) => {
		handleDeleteEventItem(eventIndex, itemIndex);
		setChangesMade((prev) => ({ ...prev, [eventIndex]: true }));
	};

	// Only disable the button for current saving event.
	const [activeSavingIndex, setActiveSavingIndex] = useState<number | null>(
		null
	);

	const [invalidItems, setInvalidItems] = useState([]);

	// Handle saving the updated items
	const handleSaveItems = async (sessionId, eventIndex) => {
		setActiveSavingIndex(eventIndex);

		const currentItems = JSON.parse(
			selectedEvent.events[eventIndex].actual_items || "[]"
		);

		try {
			const result = await handleSaveEventItems(
				sessionId,
				selectedEvent.events[eventIndex].id,
				currentItems
			);

			if (result.success) {
				// console.log("Success:", result.message);
				setInvalidItems(JSON.stringify(result.invalid_items));
				setChangesMade((prev) => ({ ...prev, [eventIndex]: false }));
				dispatch(setSnackbarOpen(true));
				dispatch(setSnackbarSeverity("success"));
				dispatch(setSnackbarText("Saved changes."));
			}
		} catch (err) {
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText("Failed to save."));
		} finally {
			setActiveSavingIndex(null);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
			PaperProps={{
				sx: {
					maxWidth: "80vw", // Set max-width to 80vw
				},
			}}
		>
			<DialogTitle id="form-dialog-title">
				Session Details
				<Button
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					Close
				</Button>
			</DialogTitle>
			<DialogContent>
				<Box>
					<p>
						{selectedEvent?.start
							? new Date(selectedEvent.start).toLocaleDateString()
							: ""}
					</p>
					<p>
						<b>{selectedEvent?.hospital}</b>
					</p>
					<p>
						<b>{selectedEvent?.surgeon}</b>
					</p>
					<p>
						Assistant Estimate:&nbsp;
						<b>
							$
							{calculateFeeEstimate(
								selectedEvent.events,
								schedules
							)?.toFixed(2)}
						</b>
					</p>
				</Box>
			</DialogContent>
			<DialogActions>
				{/* <Button onClick={handleEdit} color="primary">
                    Edit
                </Button> */}
				{deleting ? (
					<Button disabled>
						<svg width={0} height={0}>
							<defs>
								<linearGradient
									id="my_gradient"
									x1="0%"
									y1="0%"
									x2="0%"
									y2="100%"
								>
									<stop offset="0%" stopColor="#e01cd5" />
									<stop offset="100%" stopColor="#1CB5E0" />
								</linearGradient>
							</defs>
						</svg>
						<CircularProgress
							size={18}
							sx={{
								"svg circle": {
									stroke: "url(#my_gradient)",
								},
							}}
						/>
					</Button>
				) : (
					<Button
						variant="contained"
						onClick={handleDelete}
						color="error"
						size="small"
						disableElevation
					>
						Delete Session
					</Button>
				)}
			</DialogActions>
			<DialogContent>
				<Box>
					{selectedEvent?.events?.length > 0 ? (
						selectedEvent.events.map((event, index) => {
							// Calculate individual patient fee estimate
							return (
								<Accordion
									key={index}
									sx={{
										backgroundColor:
											event.items_validated === true
												? "#e3fce4" // Light green for validated
												: event.items_validated ===
												  false
												? "#fce4e4" // Light red for invalid
												: "#f7f7f7", // Light grey for null (not validated yet)
									}}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
									>
										<Typography variant="body1">
											<b>Patient:</b>{" "}
											{event.patient.first_name}{" "}
											{event.patient.last_name} |{" "}
											<b>Procedure:</b> {event.procedure}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography variant="body2">
											DOB: {event.patient.date_of_birth}
										</Typography>
										<Typography variant="body2">
											Medicare: {event.patient.medicare}
										</Typography>
										<Typography variant="body2">
											Health Fund:{" "}
											{Array.isArray(
												event.patient.policies
											) &&
											event.patient.policies.length > 0
												? event.patient.policies[0]
														.healthFund?.name
													? event.patient.policies[0]
															.healthFund.name // Use health fund name if it exists
													: event.patient.policies[0]
															.healthFund
															.aliases[1] // Fallback to aliases[1] if no name exists
												: "N/A"}
										</Typography>
										<Typography variant="body2">
											Health Fund #:{" "}
											{Array.isArray(
												event.patient.policies
											) &&
											event.patient.policies.length > 0
												? event.patient.policies[0]
														.health_fund_no
												: "N/A"}
										</Typography>
										<Typography variant="body2">
											Anaesthetist:{" "}
											{event.anaesthetist?.name || "N/A"}
										</Typography>
										<br />
										<Box
											sx={{
												display: "flex",
												flexWrap: "wrap",
												alignItems: "baseline",
												gap: 1,
											}}
										>
											<Typography variant="body2">
												Item No.{" "}
											</Typography>
											{JSON.parse(
												event.actual_items
											)?.map((item, itemIndex) => (
												<TextField
													key={itemIndex}
													value={item}
													onChange={(e) =>
														handleItemChange(
															index,
															itemIndex,
															e.target.value
														)
													}
													error={invalidItems.includes(
														item
													)}
													inputProps={{
														style: {
															paddingTop: 0,
															paddingBottom: 0,
															paddingRight: 0, // Remove right padding
															width: "60px", // Adjust for compact display
														},
													}}
													sx={{
														"& .MuiOutlinedInput-root":
															{
																pr: 0, // Remove right padding
															},
														bgcolor:
															"background.paper",
													}}
													size="small"
													variant="outlined"
													InputProps={{
														endAdornment: (
															<IconButton
																size="small"
																color="error"
																sx={{
																	padding: 0, // Remove padding from the icon
																}}
																onClick={() =>
																	handleItemDelete(
																		index,
																		itemIndex
																	)
																}
															>
																<DeleteIcon />
															</IconButton>
														),
													}}
												/>
											))}
											{/* Input field for adding new item */}
											<TextField
												value={
													newItemValues[index] || ""
												}
												onChange={(e) =>
													handleNewItemInputChange(
														index,
														e.target.value
													)
												}
												inputProps={{
													style: {
														paddingTop: 0,
														paddingBottom: 0,
														paddingRight: 0, // Remove right padding
														width: "60px",
													},
												}}
												sx={{
													"& .MuiOutlinedInput-root":
														{
															pr: 0, // Remove right padding
														},
													bgcolor: "background.paper",
												}}
												size="small"
												variant="outlined"
												InputProps={{
													endAdornment: (
														<IconButton
															size="small"
															color="primary"
															sx={{
																padding: 0, // Remove padding from the icon
															}}
															onClick={() =>
																handleItemAdd(
																	index
																)
															}
														>
															<AddCircleIcon />
														</IconButton>
													),
												}}
											/>
											{event.items_validated && (
												<Tooltip title="All items are valid">
													<CheckCircleIcon
														sx={{ color: "green" }}
														fontSize="small"
													/>
												</Tooltip>
											)}
											<Box sx={{ marginLeft: "auto" }}>
												<Button
													disableElevation
													variant="contained"
													size="small"
													color="primary"
													onClick={(_e) =>
														handleSaveItems(
															selectedEvent.id,
															index
														)
													}
													disabled={
														(saving &&
															activeSavingIndex ===
																index) ||
														(event.items_validated !==
															null &&
															!changesMade[index])
													}
													sx={{
														width: "180px", // Set a fixed width for the button
														display: "flex", // Center-align spinner and text
														justifyContent:
															"center",
														alignItems: "center",
													}}
												>
													Validate and Save
												</Button>
											</Box>
										</Box>

										<br />
										{/* Individual Patient Fee Estimate */}
										<Typography variant="body2">
											Assistant Estimate:&nbsp;
											<b>
												$
												{calculateSinglePtFeeEstimate(
													event,
													schedules
												).toFixed(2)}
											</b>
										</Typography>
									</AccordionDetails>
								</Accordion>
							);
						})
					) : (
						<Typography variant="body2">
							No additional events available.
						</Typography>
					)}
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default CalendarDialog;

import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import { addMonths, format, isSameMonth, setYear } from "date-fns";
import { enAU } from "date-fns/locale";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import { useAppDispatch, useAppSelector } from "../../../store/hooks.ts";
import { setCalendarDate } from "../../../store/slices/calendarSlice.ts";
import Box from "@mui/material/Box";
import { Button, IconButton } from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
import { DateView } from "@mui/x-date-pickers/models/views";

const CustomHeader = ({
	currentMonth,
	onMonthChange,
	toggleViewMode,
	viewMode,
}) => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: viewMode === "day" ? "space-between" : "center",
				px: 2,
				py: 1,
			}}
		>
			{viewMode === "day" && (
				<IconButton onClick={() => onMonthChange(-1)} size="small">
					<ArrowBackIosIcon fontSize="small" />
				</IconButton>
			)}
			<Button
				variant="text"
				onClick={toggleViewMode}
				sx={{
					textTransform: "none",
					fontSize: "1.2rem",
					fontWeight: "bold",
					display: "flex",
					alignItems: "center",
					gap: 0.5,
					padding: 0,
					minWidth: "unset",
				}}
			>
				{format(currentMonth, "MMM yyyy", { locale: enAU })}
			</Button>
			{viewMode === "day" && (
				<IconButton onClick={() => onMonthChange(1)} size="small">
					<ArrowForwardIosIcon fontSize="small" />
				</IconButton>
			)}
		</Box>
	);
};

const MiniCalendar = () => {
	const currentDate = useAppSelector((state) => state.calendar.currentDate);
	const dispatch = useAppDispatch();

	const [selectedDate, setSelectedDate] = useState(new Date(currentDate));
	const [viewMode, setViewMode] = useState("day");

	const toggleViewMode = () => {
		setViewMode((prev) => (prev === "day" ? "year" : "day"));
	};

	const handleMonthChange = (direction) => {
		const newMonth = addMonths(selectedDate, direction);
		setSelectedDate(newMonth);
		setViewMode("day");
	};

	const handleDateChange = (newDate) => {
		if (newDate) {
			// Convert currentDate to a Date object if it exists
			const parsedCurrentDate = currentDate
				? new Date(currentDate)
				: null;

			// Check if the day or month has changed
			const dayChanged =
				!parsedCurrentDate ||
				newDate.getDate() !== parsedCurrentDate.getDate();
			const monthChanged =
				!parsedCurrentDate ||
				newDate.getMonth() !== parsedCurrentDate.getMonth();

			const yearChanged =
				!parsedCurrentDate ||
				newDate.getFullYear() !== parsedCurrentDate.getFullYear();

			if (yearChanged) {
				setSelectedDate(
					(prev) => new Date(setYear(prev, newDate.getFullYear()))
				);
				setViewMode("day");
			}

			if (dayChanged || monthChanged || yearChanged) {
				dispatch(setCalendarDate(newDate.toISOString()));
			}
		}
	};

	useEffect(() => {
		const newCurrentDate = new Date(currentDate);
		setSelectedDate((prevMonth) =>
			isSameMonth(newCurrentDate, prevMonth) ? prevMonth : newCurrentDate
		);
	}, [currentDate]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
			<Box
				sx={{
					maxWidth: "100%",
					overflow: "hidden",
					position: "relative",
				}}
			>
				<DateCalendar
					sx={{
						maxWidth: "100%",
						"& .MuiPickersCalendarHeader-root": {
							position: "relative",
						},
						"& .MuiYearCalendar-root": {
							maxWidth: "100%",
							overflow: "scrollY",
						},
						"& .MuiPopover-root": {
							maxWidth: "100%",
						},
					}}
					view={viewMode as DateView}
					onViewChange={(newView) => setViewMode(newView as DateView)}
					value={selectedDate}
					onChange={handleDateChange}
					reduceAnimations={true}
					slots={{
						calendarHeader: (props) => (
							<CustomHeader
								{...props}
								toggleViewMode={toggleViewMode}
								viewMode={viewMode}
							/>
						),
					}}
					slotProps={{
						calendarHeader: {
							currentMonth: selectedDate,
							onMonthChange: handleMonthChange,
						},
					}}
				/>
			</Box>
		</LocalizationProvider>
	);
};

export default MiniCalendar;
